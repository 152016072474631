import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from "@angular/forms";

import { Store } from '@ngrx/store'
import * as fromStore from '@app/users/store'

import { Errors } from "@app/shared";

import { AuthService } from "../services";

import { ActivatedRoute } from '@angular/router';

import { takeUntil, tap, Subject, distinctUntilChanged } from "rxjs";
import { CloseModal } from "@app/modals/store"
@Component({
  selector: 'app-verification-form',
  template: `
  <div class="bg-white w-full container h-full flex flex-col items-center">
    <div *ngIf="isModal" style="text-align: right; position: absolute; right: 3%; top: 3%">
      <button class="btn btn-link" type="button" (click)="close()" aria-label="Close">
        <span aria-hidden="true">
          <i class="fas fa-times text-black text-lg"></i>
        </span>
      </button>
    </div>
    <h2 class="login-title mb-2">
      Verification
    </h2>
    <p class="text-base text-center mb-3">
      Check your email for the verification code<br> to confirm your identity.
    </p>
    <form [formGroup]="form" class="w-full">
      <fieldset [disabled]="isSubmitting">
        <fieldset [ngClass]="{
          'is-invalid': verificationCode.invalid && (verificationCode.dirty || verificationCode.touched)
        }" class="relative">
          <input formControlName="verificationCode" max="999999" min="100000" type="number" id="code" class="block px-3 pb-3 pt-3 w-full text-base text-gray-900 bg-transparent rounded-xl border-1 border-black appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:border-black focus:ring-0  peer" placeholder="" />
          <label for="code" class="input_label bg-white">
            Code
          </label>
        </fieldset>
        <div *ngIf="verificationCode.invalid && (verificationCode.dirty || verificationCode.touched)" class="text-red-600 text-sm">
          Please enter valid code!
        </div>
        <div class="flex items-center justify-center mt-3">
          <button
            class="bg-black text-white rounded-xl py-3 px-5"
            type="submit"
            (click)="submit()"
            [disabled]="verificationCode.invalid"
          >
            Submit
          </button>
        </div>
      </fieldset>
    </form>
  </div>
  `,
  styles: [`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    .container{
      @apply px-10 py-4;
    }

    .login-title{
      @apply text-[30px] font-semibold;
      text-align:center;
    }
    .input_label{
      @apply absolute text-base text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-3;
    }
    .is-invalid input{
      @apply border-red-600;
    }
    .is-invalid label{
      @apply text-red-600;
    }
  `]
})
export class VerificationFormComponent implements OnInit,OnDestroy {
  destroyed$ = new Subject<any>()

  @Input() props;

  paramId;
  isModal: boolean = false

  @Output() onSubmit = new EventEmitter<any>()

  errors: Errors = new Errors();
  form: UntypedFormGroup;
  isSubmitting = false;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private auth: AuthService, private store: Store<fromStore.State>) {}

  ngOnInit() {
    if(!window.location.href.includes('verify')){
      this.isModal = true
    }
    this.form = this.fb.group({
      verificationCode: new UntypedFormControl(null, {
        validators: [Validators.required, Validators.max(999999), Validators.min(100000)],
        updateOn: "change"
      })
    });
    this.route.params.pipe(
      tap((params) => {
        this.paramId = params.id
      }),
      takeUntil(this.destroyed$),
      distinctUntilChanged()
    ).subscribe()
  }

  get verificationCode() {
    return this.form.get("verificationCode") as UntypedFormControl;
  }

  submit() {
    let id;
    if(!this.props?.verificationId){
      id = this.paramId
    }else{
      id = this.props.verificationId
    }


    let payload = {
      verificationId: id,
      verificationCode: Number(this.form.get("verificationCode").value)
    }

    this.onSubmit.emit(payload)
  }

  reset() {
    this.form.reset();
  }

  close(){
    this.store.dispatch(new CloseModal())
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
