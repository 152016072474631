import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

import { RouterModule } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { SharedModule } from '@app/shared'
import { CoreComponentsModule } from '@app/shared/buttons'



import { LoginFormComponent } from '@app/users/components/login-form.component'
import { ResetFormComponent } from '@app/users/components/reset-form.component'
import { ResetPassFormComponent } from '@app/users/components/reset-pass-form.component'
import { VerificationFormComponent } from '@app/users/components/verification-form.component'
import { RecaptchaModule } from 'ng-recaptcha'

export const COMPONENTS = [LoginFormComponent, ResetFormComponent, ResetPassFormComponent, VerificationFormComponent]

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgbModule,
    SharedModule,
    CoreComponentsModule,
    RecaptchaModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule {}
