import { Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AccountDataService } from '@app/shared/services';
import { Auth, genMockAuth } from '@app/shared/models/auth.model';
import { ToastHelper } from '@app/core/services/toast.service';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/users/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-page',
  template: `
    <div class="auth-page h-100">
      <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-md-4">
          <form  class="m-form m-form--fit m-form--label-align-right" id="m_form_1">
        <div class="m-portlet__body" style="padding:0rem">
        <div class="form-group m-form__group row">
            <label for="example-text-input" class="col-3 col-form-label">
              Name
            </label>
            <div class="col-8">
              <p></p>
            </div>
          </div>
          <div class="form-group m-form__group row">
            <label for="example-text-input" class="col-3 col-form-label">
              Email
            </label>
            <div class="col-8">
              <input type="text" class="form-control m-input" name="email" placeholder="Enter Email" [ngModel]="authObject.email" (ngModelChange)='authObject.email = $event.toLowerCase()'>
            </div>
          </div>
          <div class="form-group m-form__group row">
              <label for="example-text-input" class="col-3 col-form-label">
                New Password
              </label>
              <div class="col-8 relative">
                <input [type]="showPassword ? 'text' : 'password'" class="form-control m-input" name="newPassword" placeholder="Enter Password" [(ngModel)]='authObject.newPassword'>
                <i [ngClass]="{
                  'fa-eye': showPassword,
                  'fa-eye-slash': !showPassword
                }" (click)="showPassword=!showPassword"  class="fa-solid input_icon"></i>
              </div>
            </div>
        </div>
        <div class="modal-footer" style="margin-top:1rem">
          <button type="button" (click)="callSave()" class="btn btn-primary">
            Save
          </button>
        </div>
      </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .col-md-4 {
      background-color: white;
      padding: 1rem 2rem 2rem;
      box-shadow: 0 0 20px 0px #0000001f;
      border-radius: 1rem;
    }
    .input_icon{
      position: absolute;
      cursor:pointer;
      top:35%;
      right: 0px;
      padding-right:20px;
      transform: translateY(-50%);
      scale: 0.9;
    }
  `]
})
export class RegisterPageComponent implements OnInit, OnDestroy {
  @Output() onSave = new EventEmitter();
  authObject = {
    newPassword: '',
    email: ''
  }
  invalid = false;
  show = false;
  authString;
  showPassword = false

  constructor(private store: Store<fromStore.State>, private _Router: Router, private notify: ToastHelper) {}

  callSave() {
    if (!this.invalid) {
      if (!this.authObject?.newPassword) {
        delete this.authObject?.newPassword
      }
      if (this.authObject?.newPassword?.length < 6) {
        this.notify.error('Password must be at least 6 characters long')
        return
      }
      this.authString = this.getAuthObject();
      this.onSave.emit(this.authObject);
      this.show = false;

    }
  }

  getAuthObject() {
    return this.authObject.email;
  }

  ngOnInit() {
    this.authString = this.getAuthObject();
  }


  ngOnDestroy() {
  }
}
