import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from "@angular/forms";

import { Errors } from "@app/shared";
import { ToastHelper } from "@app/core/services/toast.service";

@Component({
  selector: "app-reset-form",
  template: `
    <list-errors [errors]="errors"></list-errors>
    <h2 class="login-title" >
      Reset
    </h2>
    <p class="text-base text-center px-5 mb-3">
      Enter the email address associated with your account and we'll send you a
      link to reset your password.
    </p>
    <form [formGroup]="form">
      <fieldset [disabled]="isSubmitting">
        <fieldset [ngClass]="{
          'is-invalid': email.invalid && (email.dirty || email.touched)
        }" class="relative">
          <input formControlName="email" type="text" id="email" class="block px-3 pb-3 pt-3 w-full text-base text-gray-900 bg-transparent rounded-xl border-1 border-black appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:border-black focus:ring-0  peer" placeholder=" " />
          <label for="email" class="input_label">
            Email
          </label>
        </fieldset>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-red-600 text-sm">
          Please enter valid email!
        </div>
        <div class="flex items-center justify-between mt-3">
          <button class="text-sm text-black" (click)="goToLogin.emit()">
            <i class="fa-solid fa-chevron-left text-xs mr-1"></i>
            Go to login
          </button>
          <button
            class="bg-black text-white rounded-xl py-3 px-5 ml-auto "
            type="submit"
            (click)="submit()"
          >
            Reset
          </button>
        </div>
      </fieldset>
    </form>
  `,
  styles: [`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    .login-title{
      @apply text-[30px] font-semibold;
      text-align:center;
    }
    .input_label{
      @apply absolute text-base text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-3;
    }
    .is-invalid input{
      @apply border-red-600;
      background: #FDF2F2;
    }
    .is-invalid label{
      @apply text-red-600;
    }
  `]
})
export class ResetFormComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Output() goToLogin = new EventEmitter();

  @Input() inputObj;

  errors: Errors = new Errors();
  form: UntypedFormGroup;
  isSubmitting = false;

  constructor(private fb: UntypedFormBuilder, private notify: ToastHelper) {}

  ngOnInit() {
    // use FormBuilder to create a form group
    this.form = this.fb.group({
      email: new UntypedFormControl("", {
        validators: [Validators.required, Validators.email],
        updateOn: "blur"
      })
    });
  }

  get email() {
    return this.form.get("email") as UntypedFormControl;
  }

  submit() {
    if (this.form.invalid) {
      this.notify.error("Please enter valid form inputs!");
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    // Control View from Store
    // this.isSubmitting = true;
    this.errors = new Errors();

    // this.form.value will return obj of the form
    const email: string = this.form.get("email").value;

    // trim values
    email.trim();

    // set payload
    const payload = { email };

    this.onSubmit.emit(payload);
  }

  reset() {
    this.form.reset();
  }
}
