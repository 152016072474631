import { Component, OnInit, OnDestroy } from '@angular/core'

import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import * as fromStore from '@app/users/store'

import { ToastHelper } from '@app/core/services/toast.service'
import { Router, NavigationEnd } from '@angular/router'
import { takeUntil, tap, filter } from 'rxjs/operators'
import { CloseModal } from '@app/modals/store/actions/layout.actions'

@Component({
  selector: 'app-account-form-modal',
  template: ` <div class="modal-body">
    <app-login-form
      *ngIf="page === 'login'"
      [inputObj]="inputObj"
      (onSubmit)="login($event)"
      (goToReset)="page = 'reset'"
    ></app-login-form>
    <app-reset-form
      *ngIf="page === 'reset'"
      [inputObj]="inputObj"
      (onSubmit)="reset($event)"
      (goToLogin)="page = 'login'"
    ></app-reset-form>
  </div>`
})
export class LoginModalComponent implements OnInit, OnDestroy {
  page = 'login'
  inputObj = { email: '' }
  destroyed$ = new Subject<boolean>()
  constructor(private store: Store<fromStore.State>, public notify: ToastHelper, private _Router: Router) {}

  ngOnInit() {
    if (this._Router.url === '/login') {
      this.store.dispatch(new CloseModal())
    }
    this._Router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd),
        tap(_ => (this._Router.url === '/login' ? this.store.dispatch(new CloseModal()) : null))
      )
      .subscribe()
  }

  login(event) {
    this.store.dispatch(new fromStore.Login(event))
  }
  reset(event) {
    this.store.dispatch(new fromStore.Reset(event))
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
