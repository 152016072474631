import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Observable, combineLatest, map, filter, take } from 'rxjs'

import * as fromAuth from '@app/users/store'
import { Store } from '@ngrx/store'
import { AuthService } from '@app/users/services'
import { Router } from '@angular/router'


@Injectable()
export class LoginPassGuard  {
  constructor(
    private _AuthService: AuthService,
    private store: Store<fromAuth.State>,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(fromAuth.getTokenChecked),
      this.store.select(fromAuth.getLoggedIn),
    ]).pipe(
      map(([checked, isLoggedIn]) => {
        const token = this._AuthService.getToken()
        if (isLoggedIn) {
          this.store.select(fromAuth.getUser).pipe(
            take(1)
          ).subscribe(user => {
            if (user) {
              if(user.type === 'MECHANIC'){
                this.router.navigate(['/shop'])
              }else{
                this.router.navigate(['/'])
              }
            }
          })
        }
        if (!token) {
          return true
        }
        if (!checked && token) {
          this.store.dispatch(new fromAuth.LoginToken())
        }
      }),
      filter(i => i)
    )
  }
}
