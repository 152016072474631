import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/users/store';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router'

@Component({
  selector: 'app-verification-page',
  template: `
    <div class="auth-page h-100">
      <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-md-4">
            <app-verification-form (onSubmit)="verify($event)"></app-verification-form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
  .col-md-4 {
    background-color: white;
    padding: 1rem 2rem 2rem;
    box-shadow: 0 0 20px 0px #0000001f;
    border-radius: 1rem;
    max-width: 40%;
    width: 40%;
  }
`]
})
export class VerificationPageComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  private subscription
  public reqObj = {};

  constructor(private store: Store<fromStore.State>, private _Router: Router) {}

  ngOnInit() {
    this.subscription = this.store.select(fromStore.getUser).subscribe(user => {
      if (user) {
        this.getLoginPath(user)
      }
    })
  }
  getLoginPath = user => {
    switch (user.type) {
      case 'MECHANIC':
        return this._Router.navigate(['/shop'])
      default:
        return this._Router.navigate(['/'])
    }
  }

  verify(payload){
    this.store.dispatch(new fromStore.LoginVerification(payload))
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
