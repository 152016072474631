import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy  } from '@angular/core'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import { Router } from '@angular/router'

import { Errors } from '@app/shared'
import { ToastHelper } from '@app/core/services/toast.service'
import { environment } from 'environments/environment'
import { CloseModal } from '@app/modals/store/actions/layout.actions'
import { RecaptchaComponent } from 'ng-recaptcha'
RecaptchaComponent.prototype.ngOnDestroy = function () {
  if (this.subscription) {
    this.subscription.unsubscribe()
  }
}

@Component({
  selector: 'app-login-form',
  templateUrl: `./login-form.template.html`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    .login-title{
      @apply text-[30px] font-semibold ml-[10px];
    }


    .input_label{
      @apply absolute text-base text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-3;
    }
    .input_icon{
      @apply absolute cursor-pointer top-[50%] translate-y-[-50%] right-[0px] pr-[15px];
    }

    .is-invalid input{
      @apply border-red-600;
      background: #FDF2F2;
    }
    .is-invalid label{
      @apply text-red-600;
    }

  `]
})
export class LoginFormComponent implements OnInit {
  @Output() onSubmit = new EventEmitter()
  @Output() goToReset = new EventEmitter()

  @Input() inputObj
  page = 'login'

  errors: Errors = new Errors();
  form: FormGroup
  isSubmitting = false;
  apiUrl = environment.apiUrl;
  siteKey: string = environment.google.reCAPTCHA;
  showPassword:boolean = false

  // captchas: string;

  @ViewChild('captchaRef') captchaObj: any

  constructor(
    private fb: FormBuilder,
    private notify: ToastHelper,
    private store: Store<fromCoreStore.State>,
    public router: Router,
    private cdref: ChangeDetectorRef
  ) { this.captcha = '' }

  ngOnInit() {
    // use FormBuilder to create a form group
    this.form = this.fb.group({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur'
      }),
      password: new FormControl('', {
        validators: [Validators.required]
      })
    })
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
 }

  get email() {
    return this.form.get('email') as FormControl
  }

  get password() {
    return this.form.get('password') as FormControl
  }

  submit() {
    this.errors = new Errors()

    this.captchaObj.grecaptcha.execute()

  }

  reset() {
    this.form.reset()
  }
  captcha: string
  // resolved(captchaResponse: string) {
  //   this.captcha = captchaResponse
  // }
  resolved(captchaResponse: string) {
    const email: string = this.form.get('email').value
    const password: string = this.form.get('password').value

    // trim values
    email.trim()
    password.trim()

    // set payload
    const payload = {
      email: email,
      password: password
    }

    this.captcha = captchaResponse

    if (!this.captcha.length) {
      return
    }

    this.onSubmit.emit(payload)
  }

  close() {
    this.store.dispatch(new fromCoreStore.SetSelectTemplate({}))
    this.store.dispatch(new CloseModal())
  }
}
