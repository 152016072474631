import { Component, OnInit, OnDestroy } from '@angular/core'

import { Store } from '@ngrx/store'
import * as fromStore from '@app/users/store'
import { Router } from '@angular/router'

@Component({
  selector: 'app-login-page',
  template: `
    <div class="auth-page h-100">
      <div class="container h-100">
        <div class="flex h-100 justify-center items-center">
          <div class="max-w-xl bg-white rounded-xl p-10">
            <app-login-form
              *ngIf="page === 'login'"
              [inputObj]="inputObj"
              (onSubmit)="login($event)"
              (goToReset)="page = 'reset'"
            ></app-login-form>
            <app-reset-form
              *ngIf="page === 'reset'"
              [inputObj]="inputObj"
              (onSubmit)="reset($event)"
              (goToLogin)="page = 'login'"
            ></app-reset-form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
    .max-w-xl{
      @apply p-10;
      width: 571px;
    }
    `
  ]
})
export class LoginPageComponent implements OnInit, OnDestroy {
  private subscription

  inputObj = { email: '' }

  page = 'login'

  constructor(private store: Store<fromStore.State>, private _Router: Router) {}

  ngOnInit() {
    this.subscription = this.store.select(fromStore.getUser).subscribe(user => {
      if (user) {
        this.getLoginPath(user)
      }
    })
  }

  getLoginPath = user => {
    switch (user.type) {
      case 'MECHANIC':
        return this._Router.navigate(['/shop'])
      default:
        return this._Router.navigate(['/'])
    }
  }

  login(event) {
    this.store.dispatch(new fromStore.Login(event))
  }

  reset(event) {
    this.store.dispatch(new fromStore.Reset(event))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
