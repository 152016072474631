import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { FeatureName, reducers, effects } from '@app/users/store'

import { LoginPassGuard } from './guards/login-pass.guard'
import { ResetPassGuard } from '@app/users/guards/reset-pass.guard'
import { SharedModule } from '@app/shared'

import { LoginPageComponent } from '@app/users/containers/login-page.component'
import { RegisterPageComponent } from '@app/users/containers/register-page.component'
import { LoginModalComponent } from '@app/users/containers/login-modal.component'
import { VerificationModalComponent } from '@app/users/containers/verification-modal.component'
import { ResetPassPageComponent } from '@app/users/containers/reset-pass-page.component'
import { VerificationPageComponent } from '@app/users/containers/verification-page.component'

import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  ReCaptchaV3Service,
} from 'ng-recaptcha';

import { ComponentsModule } from '@app/users/components'

const UserRouting = RouterModule.forChild([
  {
    path: '',
    component: LoginPageComponent,
    canActivate: [LoginPassGuard]
  },
  {
    path: 'resetPassword',
    canActivate: [ResetPassGuard],
    component: ResetPassPageComponent
  },
  {
    path: 'registration',
    component: RegisterPageComponent
  },
  {
    path: 'verify/:id',
    component: VerificationPageComponent,
    canActivate: [LoginPassGuard]
  }
])

@NgModule({
    imports: [
        UserRouting,
        RecaptchaModule,
        SharedModule,
        ComponentsModule,
        StoreModule.forFeature(FeatureName, reducers),
        EffectsModule.forFeature(effects)
    ],
    declarations: [LoginPageComponent, VerificationPageComponent, LoginModalComponent, ResetPassPageComponent, RegisterPageComponent, VerificationModalComponent],
    providers: [ResetPassGuard, LoginPassGuard,
      {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LcE_NolAAAAAJn9N-b-w6DHXZu3a3dwUQMegABD',
        badge: 'inline',
        size: 'invisible',
      } as RecaptchaSettings,
    },
  ]
})
export class UsersModule {}
