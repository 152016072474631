<div>
<div *ngIf="router.url !== '/login'" style="text-align: right; ">
  <button class="btn btn-link" type="button" (click)="close()" aria-label="Close">
    <span aria-hidden="true">
      <i class="fas fa-times text-black text-lg"></i>
    </span>
  </button>
</div>
<list-errors [errors]="errors"></list-errors>
<!-- <app-input ></app-input> -->
<div class="text-center flex items-center justify-center" style="margin-bottom:30px">
  <img src="assets/haulex-logo-black.png" style="height: 60px;" />
  <h2 class="login-title" >
    Login
  </h2>
</div>


<form [formGroup]="form">
  <fieldset [disabled]="isSubmitting">
    <fieldset [ngClass]="{
      'is-invalid': email.invalid && (email.dirty || email.touched)
    }" class="relative">
      <input formControlName="email" type="text" id="email" class="block px-3 pb-3 pt-3 w-full text-base text-gray-900 bg-transparent rounded-xl border-1 border-black appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:border-black focus:ring-0  peer" placeholder=" " />
      <label for="email" class="input_label">
        Email
      </label>
    </fieldset>

    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-red-600 text-sm">
        Please enter valid email!
    </div>

    <fieldset [ngClass]="{
      'is-invalid': password.invalid && (password.dirty || password.touched)
    }" class="relative mt-3">
      <input [type]="showPassword ? 'text' : 'password'" formControlName="password" id="password" class="block px-3 pb-3 pt-3 w-full text-base text-gray-900 bg-transparent rounded-xl border-1 border-black appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:border-black focus:ring-0  peer" placeholder=" " />
      <label for="password" class="input_label">
        Password
      </label>
        <i [ngClass]="{
          'fa-eye': showPassword,
          'fa-eye-slash': !showPassword
        }" (click)="showPassword=!showPassword" class="fa-solid input_icon"></i>
    </fieldset>

    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-red-600 text-sm">
      Please enter password it's required.
    </div>

    <button class="bg-black  rounded-xl py-3 w-100 mt-3" type="submit" (click)="submit()">
      <span class="text-white text-base">Login</span>
    </button>
    <div class="text-sm text-gray-500 mt-1"  (click)="goToReset.emit()">
      Forgot password?
    </div>

  </fieldset>
</form>
</div>

<div style="margin-top:30px; filter: grayscale(1)">
  <re-captcha
    #captchaRef
    [theme]="'dark'"
    [siteKey]="siteKey"
    (resolved)="resolved($event)"
    [size]="'invisible'"
  ></re-captcha>
</div>

