import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/users/store';
import { ActivatedRoute } from '@angular/router';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-reset-pass-page',
  template: `
    <div class="auth-page h-100">
      <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-md-4">
            <app-reset-pass-form (onSubmit)="resetPass($event)"></app-reset-pass-form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
  .col-md-4 {
    background-color: white;
    padding: 1rem 2rem 2rem;
    box-shadow: 0 0 20px 0px #0000001f;
    border-radius: 1rem;
  }
`]
})
export class ResetPassPageComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  private reqObj = {};

  constructor(private store: Store<fromStore.State>, private _ActivatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this._ActivatedRoute.queryParams
      .pipe(
        takeUntil(this.destroyed$),
        tap((queryParams: any) => {
          this.reqObj = queryParams;
        })
      )
      .subscribe();
  }

  resetPass(event) {
    this.store.dispatch(new fromStore.ResetPassword({ ...this.reqObj, ...event }));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
