import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'

import { AuthService } from '@app/users/services'
import { catchError, switchMap } from 'rxjs/operators'

@Injectable()
export class ResetPassGuard  {
  constructor(
    private _AuthService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._AuthService.canReset(route.queryParams)
      .pipe(
        switchMap(result => of(result)),
        catchError(err => of(!err))
      )
  }
}
