import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/users/store';
// import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-verification-modal',
  template: `
    <div class="auth-page h-100">
      <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-md-4">
            <app-verification-form (onSubmit)="verify($event)" [props]="props"></app-verification-form>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
  .col-md-4 {
    background-color: white;
    padding: 1rem 2rem 2rem;
    box-shadow: 0 0 20px 0px #0000001f;
    width: 100%;
  }
`]
})
export class VerificationModalComponent implements OnDestroy {
  destroyed$ = new Subject<boolean>();

  @Input() props;

  constructor(private store: Store<fromStore.State>) {}

  verify(payload){
    this.store.dispatch(new fromStore.LoginVerification(payload))
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
