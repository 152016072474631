import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from "@angular/forms";

import { Errors } from "@app/shared";
import { ToastHelper } from "@app/core/services/toast.service";

@Component({
  selector: "app-reset-pass-form",
  template: `
    <list-errors [errors]="errors"></list-errors>
    <h2 class="text-xs-center" style="text-align: center;">Reset Password</h2>
    <form [formGroup]="form">
      <fieldset [disabled]="isSubmitting">
        <fieldset class="form-group relative">
          <input
            placeholder="New password"
            style="height: 4rem"
            class="form-control form-control-lg"
            [ngClass]="{
              'is-invalid':
                password.invalid && (password.dirty || password.touched)
            }"
            formControlName="password"
            [type]="showPassword ? 'text' : 'password'"
          />
          <i (click)="showPassword=!showPassword" style="
            position: absolute;
            cursor:pointer;
            top:50%;
            right: 0px;
            padding-right:15px;
            transform: translateY(-50%);
            scale: 0.9;
          " [ngStyle]="{
              'padding-right': password.invalid && (password.dirty || password.touched) ? '30px' : '15px'
          }" [ngClass]="{
            'fa-eye': showPassword,
            'fa-eye-slash': !showPassword,
            'invalid-eye': password.invalid && (password.dirty || password.touched)
          }" *ngIf="form.value.password" class="fa-solid"></i>
          <div
            *ngIf="password.invalid && (password.dirty || password.touched)"
            class="invalid-feedback"
          >
            Please enter password it's required.
          </div>
        </fieldset>
        <fieldset class="form-group relative">
          <input
            #passConfirm
            placeholder="Confirm password"
            style="height: 4rem"
            class="form-control form-control-lg"
            [ngClass]="{
              'is-invalid':
                password.invalid && (password.dirty || password.touched)
            }"
            formControlName="passwordConfirm"
            [type]="showPassword ? 'text' : 'password'"
          />
          <i (click)="showPassword=!showPassword" style="
            position: absolute;
            cursor:pointer;
            top:50%;
            right: 0px;
            padding-right:15px;
            transform: translateY(-50%);
            scale: 0.9;
          " [ngStyle]="{
              'padding-right': password.invalid && (password.dirty || password.touched) ? '30px' : '15px'
          }" [ngClass]="{
            'fa-eye': showPassword,
            'fa-eye-slash': !showPassword,

          }" *ngIf="form.value.passwordConfirm" class="fa-solid"></i>
          <div
            *ngIf="
              passwordConfirm.invalid &&
              (passwordConfirm.dirty || passwordConfirm.touched)
            "
            class="invalid-feedback"
          >
            Please enter password it's required.
          </div>
        </fieldset>
        <button
          class="btn btn-lg btn-primary pull-xs-right"
          style="float:right"
          type="submit"
          (click)="submit()"
        >
          Reset Password
        </button>
      </fieldset>
    </form>
  `
})
export class ResetPassFormComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();

  errors: Errors = new Errors();
  form: UntypedFormGroup;
  isSubmitting = false;
  showPassword = false

  constructor(private fb: UntypedFormBuilder, private notify: ToastHelper) {}

  ngOnInit() {
    // use FormBuilder to create a form group
    this.form = this.fb.group({
      password: new UntypedFormControl("", {
        validators: [Validators.required]
      }),
      passwordConfirm: new UntypedFormControl("", {
        validators: [Validators.required]
      })
    });
  }

  get password() {
    return this.form.get("password") as UntypedFormControl;
  }

  get passwordConfirm() {
    return this.form.get("passwordConfirm") as UntypedFormControl;
  }

  submit() {
    if (
      this.form.invalid ||
      this.form.get("password").value !== this.form.get("passwordConfirm").value
    ) {
      this.notify.error("Please enter valid form inputs!");
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    // Control View from Store
    // this.isSubmitting = true;
    this.errors = new Errors();

    // this.form.value will return obj of the form
    const password: string = this.form.get("password").value;

    // trim values
    password.trim();

    // set payload
    const payload = { password };

    this.onSubmit.emit(payload);
  }
}
